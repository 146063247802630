<template>
  <div class="container-fluid">
    <b-btn
      variant="none"
      @click="getResumoFinanceiro"
      ><b-icon-recycle /> Atualizar</b-btn
    >
    <div class="row">
      <div class="col-12 col-sm-4 pl-1 pr-1">
        <h4
          class="text-center bg-primary text-white p-2 cursor"
          @click="showCaixas = !showCaixas"
        >
          <i class="fa fa-cash-register" /> Caixas
        </h4>
        <b-collapse :visible="showCaixas">
          <table class="table">
            <tr class="text-success">
              <th><b-icon-plus-circle-fill /> Vendas</th>
              <td
                class="text-right"
                v-if="dados && dados.mesas && dados.caixa"
              >
                {{ dados.caixa.vendas || 0 | currency }}
              </td>
            </tr>
            <tr class="text-success">
              <th><b-icon-plus-circle-fill /> Trocos</th>
              <td
                class="text-right"
                v-if="dados && dados.caixa && dados.caixa.informado"
              >
                {{ dados.caixa.informado.vl_aberto || 0 | currency }}
              </td>
            </tr>
            <tr class="text-danger">
              <th><b-icon-dash-circle-fill /> Saídas</th>
              <td
                class="text-right"
                v-if="dados && dados.caixa && dados.caixa.saidas"
              >
                {{ dados.caixa.saidas || 0 | currency }}
              </td>
            </tr>

            <tr>
              <th><b-icon-arrow-return-right /> Sub-total</th>
              <td
                class="text-right"
                v-if="dados && dados.mesas && dados.caixa"
              >
                {{
                  ((dados.caixa.vendas || 0) +
                    (dados.caixa.informado.vl_aberto || 0))
                    | currency
                }}
              </td>
            </tr>

            <tr class="text-info">
              <th
                colspan="2"
                class="text-center"
              >
                <b-icon-info-circle-fill /> Informado
              </th>
            </tr>
            <tr
              style="font-size: 11px"
              v-if="dados && dados.caixa && dados.caixa.informado"
            >
              <th class="pl-4">Dinheiro</th>
              <td
                class="text-right"
                v-if="
                  dados &&
                  dados.caixa &&
                  dados.caixa.informado &&
                  dados.caixa.informado.vl_dim
                "
              >
                {{ (dados.caixa.informado.vl_dim || 0) | currency }}
              </td>
            </tr>
            <tr
              style="font-size: 11px"
              v-if="
                dados &&
                dados.caixa &&
                dados.caixa.informado &&
                dados.caixa.informado.vl_car
              "
            >
              <th class="pl-4">Cartão</th>
              <td class="text-right">
                {{ (dados.caixa.informado.vl_car || 0) | currency }}
              </td>
            </tr>
            <tr v-if="dados && dados.caixa && dados.caixa.informado">
              <th><b-icon-arrow-return-right /> Sub-total</th>
              <td class="text-right">
                {{
                  ((dados.caixa.informado.vl_dim || 0) +
                    (dados.caixa.informado.vl_car || 0))
                    | currency
                }}
              </td>
            </tr>
            <tr
              v-if="dados && dados.caixa && dados.caixa.diferenca"
              class="text-info"
              :class="{ 'text-danger': (dados.caixa.diferenca || 0) < 0 }"
            >
              <th
                colspan="2"
                class="text-center"
              >
                <b-icon-exclamation-triangle-fill /> Diferença
              </th>
            </tr>
            <tr v-if="dados && dados.caixa && dados.caixa.diferenca">
              <td
                class="text-center font-weight-bold text-info"
                :class="{ 'text-danger': (dados.caixa.diferenca || 0) < 0 }"
                colspan="2"
              >
                {{ (dados.caixa.diferenca || 0) | currency }}
              </td>
            </tr>
          </table>
        </b-collapse>
      </div>

      <div class="col-12 col-sm-4 pl-1 pr-1">
        <h4
          class="text-center bg-primary text-white p-2 cursor"
          @click="showEntregas = !showEntregas"
        >
          <i class="fa fa-motorcycle" /> Entregas
        </h4>
        <b-collapse :visible="showEntregas">
          <table class="table" v-if="dados && dados.entregas">
            <tr class="text-success">
              <th><b-icon-plus-circle-fill /> Vendas</th>
              <td class="text-right">
                {{ dados.entregas.vendas || 0 | currency }}
              </td>
            </tr>
            <tr class="text-success">
              <th><b-icon-plus-circle-fill /> Troco</th>
              <td class="text-right">
                {{ dados.entregas.trocos || 0 | currency }}
              </td>
            </tr>

            <tr class="text-danger">
              <th><b-icon-dash-circle-fill /> Pagamentos</th>
              <td class="text-right">
                {{ dados.entregas.pagamentos || 0 | currency }}
              </td>
            </tr>

            <tr>
              <th><b-icon-arrow-return-right /> Sub-total</th>
              <td class="text-right">
                {{
                  ((dados.entregas.vendas || 0) + (dados.entregas.trocos || 0))
                    | currency
                }}
              </td>
            </tr>

            <tr class="text-info">
              <th
                class="text-center"
                colspan="2"
              >
                <b-icon-info-circle-fill /> Informado
              </th>
            </tr>
            <tr style="font-size: 11px">
              <th class="pl-4">Dinheiro</th>
              <td class="text-right">
                {{ (dados.entregas.informado.vl_dim || 0) | currency }}
              </td>
            </tr>
            <tr style="font-size: 11px">
              <th class="pl-4">Cartão</th>
              <td class="text-right">
                {{ (dados.entregas.informado.vl_car || 0) | currency }}
              </td>
            </tr>
            <tr>
              <th><b-icon-arrow-return-right /> Sub-total</th>
              <td class="text-right">
                {{
                  ((dados.entregas.informado.vl_dim || 0) +
                    (dados.entregas.informado.vl_car || 0))
                    | currency
                }}
              </td>
            </tr>
            <tr
              class="text-info"
              :class="{ 'text-danger': (dados.entregas.diferenca || 0) < 0 }"
            >
              <th
                colspan="2"
                class="text-center"
              >
                <b-icon-exclamation-triangle-fill /> Diferença
              </th>
            </tr>
            <tr>
              <td
                class="text-center text-info font-weight-bold"
                :class="{ 'text-danger': (dados.entregas.diferenca || 0) < 0 }"
                colspan="2"
              >
                {{ (dados.entregas.diferenca || 0) | currency }}
              </td>
            </tr>
          </table>
        </b-collapse>
      </div>

      <div class="col-12 col-sm-4 pl-1 pr-1">
        <h4
          class="text-center bg-primary text-white p-2 cursor"
          @click="showMesas = !showMesas"
        >
          <i class="fa fa-user-tie" /> Mesas
        </h4>
        <b-collapse :visible="showMesas">
          <table class="table" v-if="dados && dados.mesas">
            <tr class="text-success">
              <th><b-icon-plus-circle-fill /> Vendas</th>
              <td class="text-right">
                {{
                  ((dados.mesas.vendas || 0) - (dados.mesas.taxa || 0))
                    | currency
                }}
              </td>
            </tr>
            <tr class="text-success">
              <th><b-icon-percent /> Taxa Cobrado</th>
              <td class="text-right">{{ dados.mesas.taxa || 0 | currency }}</td>
            </tr>

            <tr>
              <th><b-icon-arrow-return-right /> Sub-total</th>
              <td class="text-right">
                {{ (dados.mesas.vendas || 0) | currency }}
              </td>
            </tr>

            <tr class="text-success">
              <th><b-icon-plus-circle-fill /> Troco</th>
              <td class="text-right">
                {{ dados.mesas.informado.vl_aberto || 0 | currency }}
              </td>
            </tr>
            <tr>
              <th><b-icon-arrow-return-right /> Sub-total</th>
              <td class="text-right">
                {{
                  ((dados.mesas.vendas || 0) +
                    (dados.mesas.informado.vl_aberto || 0))
                    | currency
                }}
              </td>
            </tr>
            <tr class="text-info">
              <th
                colspan="2"
                class="text-center"
              >
                <b-icon-info-circle-fill /> Informado
              </th>
            </tr>
            <tr style="font-size: 11px">
              <th class="pl-4">Dinheiro</th>
              <td class="text-right">
                {{ (dados.mesas.informado.vl_dim || 0) | currency }}
              </td>
            </tr>
            <tr style="font-size: 11px">
              <th class="pl-4">Cartão</th>
              <td class="text-right">
                {{ (dados.mesas.informado.vl_car || 0) | currency }}
              </td>
            </tr>
            <tr>
              <th><b-icon-arrow-return-right /> Sub-total</th>
              <td class="text-right">
                {{
                  ((dados.mesas.informado.vl_dim || 0) +
                    (dados.mesas.informado.vl_car || 0))
                    | currency
                }}
              </td>
            </tr>

            <tr
              class="text-info"
              :class="{ 'text-danger': (dados.mesas.diferenca || 0) < 0 }"
            >
              <th
                colspan="2"
                class="text-center"
              >
                <b-icon-exclamation-triangle-fill /> Diferença
              </th>
            </tr>
            <tr>
              <td
                class="text-center text-info font-weight-bold"
                :class="{ 'text-danger': (dados.mesas.diferenca || 0) < 0 }"
                colspan="2"
              >
                {{ (dados.mesas.diferenca || 0) | currency }}
              </td>
            </tr>
            <tr
              class="pt-3"
              style="font-size: 12px"
            >
              <th><b-icon-percent /> Taxa Não Cobrado</th>
              <td class="text-right">
                Qtd.{{ dados.mesas.taxa_nc.qt || 0 }} <br />
                Valor: {{ (dados.mesas.taxa_nc.vl || 0) | currency }}
              </td>
            </tr>
          </table>
        </b-collapse>
      </div>

      <div class="col-12 mt-3 text-center pl-1 pr-1">
        <h4 class="text-center bg-primary text-white p-2">
          <b-icon-shop /> Geral
        </h4>
        <table
          class="table"
          v-if="dados && dados.caixa && dados.caixa.informado"
        >
          <tr>
            <th>Local</th>
            <th>Vendas</th>
            <th>Informado</th>
            <th>Diferença</th>
          </tr>
          <tr>
            <th>Caixas</th>
            <td v-if="dados && dados.mesas && dados.caixa">
              {{ (dados.caixa.vendas || 0) | currency }}
            </td>
            <td>
              {{
                (dados.caixa.informado.vl_dim || 0) +
                  (dados.caixa.informado.vl_car || 0) || 0 | currency
              }}
            </td>
            <td>{{ dados.caixa.diferenca || 0 | currency }}</td>
          </tr>
          <tr>
            <th>Entregas</th>
            <td>
              {{ (dados.entregas.vendas || 0) | currency }}
            </td>
            <td>
              {{
                (dados.entregas.informado.vl_dim || 0) +
                  (dados.entregas.informado.vl_car || 0) || 0 | currency
              }}
            </td>
            <td>{{ dados.entregas.diferenca || 0 | currency }}</td>
          </tr>
          <tr>
            <th>Mesas</th>
            <td>
              {{ (dados.mesas.vendas || 0) | currency }}
            </td>
            <td>
              {{
                (dados.mesas.informado.vl_dim || 0) +
                  (dados.mesas.informado.vl_car || 0) || 0 | currency
              }}
            </td>
            <td>{{ dados.mesas.diferenca || 0 | currency }}</td>
          </tr>

          <tr>
            <td><b> Troco </b></td>
            <td>
              {{
                ((dados.entregas.trocos || 0) +
                  (dados.caixa.informado.vl_aberto || 0) +
                  (dados.mesas.informado.vl_aberto || 0))
                  | currency
              }}
            </td>
            <td>
              {{ 0 | currency }}
            </td>
            <td>
              {{ 0 | currency }}
            </td>
          </tr>
          <tr style="font-weight: bold; background-color: #f8f8f8">
            <th>Total</th>
            <td v-if="dados && dados.mesas && dados.caixa">
              {{
                ((dados.mesas.vendas || 0) +
                  (dados.entregas.vendas || 0) +
                  (dados.entregas.trocos || 0) +
                  (dados.caixa.vendas || 0) +
                  dados.mesas.informado.vl_aberto +
                  dados.caixa.informado.vl_aberto)
                  | currency
              }}
              <br />
              <small
                class="text-danger"
                v-if="dados && dados.mesas && dados.caixa"
              >
              </small>
            </td>
            <td>
              {{
                ((dados.mesas.informado.vl_dim || 0) +
                  (dados.mesas.informado.vl_car || 0) +
                  (dados.entregas.informado.vl_dim || 0) +
                  (dados.entregas.informado.vl_car || 0) +
                  (dados.caixa.informado.vl_dim || 0) +
                  (dados.caixa.informado.vl_car || 0))
                  | currency
              }}
            </td>
            <td>
              {{
                ((dados.mesas.diferenca || 0) +
                  (dados.entregas.diferenca || 0) +
                  (dados.caixa.diferenca || 0))
                  | currency
              }}
            </td>
          </tr>
          <!--SEM TROCO-->
          <tr>
            <td>
              <b>Valor Sem Troco</b>
              <div>
                <b>Online</b>
              </div>
              <div>
                <b>Total</b>
              </div>
            </td>
            <td>
              {{
                ((dados.mesas.vendas || 0) +
                  (dados.entregas.vendas || 0) +
                  (dados.caixa.vendas || 0))
                  | currency
              }}
              <div>
                {{ (dados.online.vendas || 0) | currency }}
              </div>
              <div>
                {{
                  (dados.online.vendas +
                    ((dados.mesas.vendas || 0) +
                      (dados.entregas.vendas || 0) +
                      (dados.caixa.vendas || 0)) || 0) | currency
                }}
              </div>
            </td>
            <td>
              {{
                ((dados.mesas.informado.vl_dim || 0) +
                  (dados.mesas.informado.vl_car || 0) +
                  (dados.entregas.informado.vl_dim || 0) +
                  (dados.entregas.informado.vl_car || 0) +
                  (dados.caixa.informado.vl_dim || 0) +
                  (dados.caixa.informado.vl_car || 0) -
                  ((dados.entregas.trocos || 0) +
                    dados.mesas.informado.vl_aberto +
                    dados.caixa.informado.vl_aberto))
                  | currency
              }}
              <div>
                <b>Depositado</b>: <br />
                {{ dados.totais.depositos | currency }}
                <br />
                <b>Diferença</b>
                <br />
                {{
                  (dados.totais.depositos -
                    ((dados.mesas.informado.vl_dim || 0) +
                      (dados.mesas.informado.vl_car || 0) +
                      (dados.entregas.informado.vl_dim || 0) +
                      (dados.entregas.informado.vl_car || 0) +
                      (dados.caixa.informado.vl_dim || 0) +
                      (dados.caixa.informado.vl_car || 0) -
                      ((dados.entregas.trocos || 0) +
                        dados.mesas.informado.vl_aberto +
                        dados.caixa.informado.vl_aberto)))
                    | currency
                }}
              </div>
            </td>
            <td>
              {{
                ((dados.mesas.informado.vl_dim || 0) +
                  (dados.mesas.informado.vl_car || 0) +
                  (dados.entregas.informado.vl_dim || 0) +
                  (dados.entregas.informado.vl_car || 0) +
                  (dados.caixa.informado.vl_dim || 0) +
                  (dados.caixa.informado.vl_car || 0) -
                  ((dados.entregas.trocos || 0) +
                    dados.mesas.informado.vl_aberto +
                    dados.caixa.informado.vl_aberto) -
                  ((dados.mesas.vendas || 0) +
                    (dados.entregas.vendas || 0) +
                    (dados.caixa.vendas || 0)))
                  | currency
              }}
            </td>
          </tr>
        </table>

        <!---
        <div class="row">
          <div class="col-12 col-md-6 pl-1 pr-1">
            <h4><i class="fa fa-list mr-1" /> Detalhamento Depósitos</h4>
            <hr />
            <b-radio-group
              :options="[
                { text: 'Histórico / Loja', value: 0 },
                { text: 'Loja / Histórico', value: 1 },
              ]"
              v-model="tipoDetalheDeposito"
            />
            <table class="table text-left table-sm" style="font-size: 11px">
              <template v-for="emp in dados.resumoDepositos">
                <tr
                  class="bg-light"
                  :key="`resumo_emp_${
                    emp.historico
                  }_${Math.random()}${Math.random()}`"
                >
                  <th>
                    {{ emp.historico || emp.empresa }}
                  </th>
                  <th class="text-center">{{ emp.valor | currencyMask }}</th>
                </tr>
                <template v-for="hist in emp.historicos">
                  <tr
                    :key="`resumo_emp_${emp.empresa}_${
                      hist.empresa
                    }_${Math.random()}`"
                  >
                    <td class="pl-3">
                      {{ hist.empresa || hist.historico }}3

                      <small
                        v-for="cart in hist.carteiras"
                        :key="`resumo_emp_${emp.empresa}_${hist.historico}_${cart.carteira}`"
                        class="pl-2"
                      >
                        <b-popover
                          title="Valor na Carteira"
                          triggers="hover focus"
                          :target="`resumo_emp_${emp.empresa}_${hist.historico}_${cart.carteira}`"
                          :content="cart.valor.toFixed(2)"
                        >
                          <i class="fa fa-wallet mr-1" /> {{ cart.carteira }}
                        </b-popover>
                      </small>
                    </td>
                    <td class="text-center">
                      {{ hist.valor | currencyMask }}
                    </td>
                  </tr>
                
                    v-b-popover.hover.top="cart.valor"
                  <tr
                    style="font-size: 8px"
                    v-for="cart in hist.carteiras"
                    :key="`resumo_emp_${emp.empresa}_${hist.historico}_${cart.carteira}`"
                  >
                    <th class="pl-5">
                      <i class="fa fa-wallet mr-1" /> {{ cart.carteira }}
                    </th>
                    <th class="text-center">
                      {{ cart.valor | currencyMask }}
                    </th>
                  </tr>
                  -->
        <!--
                </template>
              </template>
            </table>
          </div>
          <div class="col-12 col-md-6 pl-1 pr-1">
            <h4><b-icon-exclamation-circle-fill /> Pendencias de Caixa</h4>
            <hr />
            <table class="table table-sm mt-4" style="font-size: 10px">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Usuário</th>
                  <th>Caixa/Moto</th>
                  <th>Valor</th>
                  <th>Observações</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="pend in dados.pendenciasCaixa"
                  :key="`pendencias${pend.id}_${Math.random()}`"
                >
                  <td v-if="pend.dt_caixa">
                    {{ pend.dt_caixa | moment("DD/MM/YYYY") }}
                  </td>
                  <td>{{ pend.usuario }}</td>
                  <td>{{ pend.caixa || pend.moto }}</td>
                  <td>
                    <span
                      :class="
                        (pend.vl_passando || 0) - (pend.vl_faltando || 0) > 0
                          ? 'text-success'
                          : 'text-danger'
                      "
                    >
                      {{
                        ((pend.vl_passando || 0) - (pend.vl_faltando || 0))
                          | currency
                      }}</span
                    >
                  </td>
                  <td>
                    {{ pend.obs }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 pl-1 pr-1">
            <div class="p-3">
              <div class="row" v-if="dados && dados.totais">
                <div class="col-12 col-md-2 pl-1 pr-1">
                  <b-card>
                    <b-card-title><h5>Depositado</h5></b-card-title>
                    <h5>
                      {{
                        (dados.totais.depositos +
                          dados.totais.taxas +
                          dados.totais.pagamentos)
                          | currency
                      }}
                    </h5>
                  </b-card>
                </div>
                <div class="col-12 col-md-2 pl-1 pr-1">
                  <b-card>
                    <b-card-title><h5>Pendencias</h5></b-card-title>
                    <h5>
                      {{ dados.totais.pendencias | currency }}
                    </h5>
                  </b-card>
                </div>
                <div class="col-12 col-md-3 pl-1 pr-1">
                  <b-card>
                    <b-card-title
                      ><h5>Depositado + Pendências</h5></b-card-title
                    >
                    <h5>
                      {{
                        (dados.totais.total +
                          dados.totais.taxas +
                          dados.totais.pagamentos)
                          | currency
                      }}
                    </h5>
                  </b-card>
                </div>
                <div class="col-12 col-md-2 pl-1 pr-1">
                  <b-card>
                    <b-card-title> <h5>Vendas</h5> </b-card-title>
                    <h5 v-if="dados.mesas && dados.caixa">
                      {{
                        ((dados.mesas.vendas || 0) +
                          (dados.mesas.taxa || 0) +
                          (dados.entregas.vendas || 0) +
                          (dados.caixa.vendas || 0))
                          | currency
                      }}
                    </h5>
                  </b-card>
                </div>
                <div class="col-12 col-md-3 pl-1 pr-1">
                  <b-card body-class=" " class="bg-warning">
                    <b-card-title>
                      <h5>Diferença</h5>
                    </b-card-title>
                    <h5 v-if="dados.mesas && dados.caixa">
                      {{
                        ((dados.mesas.vendas || 0) +
                          (dados.mesas.taxa || 0) +
                          (dados.entregas.vendas || 0) +
                          (dados.caixa.vendas || 0) -
                          dados.totais.total -
                          dados.totais.taxas -
                          dados.totais.pagamentos)
                          | currency
                      }}
                    </h5>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <div class="col-12 mt-3 pl-1 pr-1">
        <h4
          class="text-center bg-primary text-white p-2 cursor"
          @click="showRelatorio = !showRelatorio"
        >
          <b-icon-shop /> Relatório de Problemas
        </h4>
        <b-collapse v-model="showRelatorio">
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="mt-4">
                <h5 class="text-danger">
                  <b-icon-percent /> Remoção de Taxa De Serviço - MESAS
                </h5>
                <table
                  class="table table-sm"
                  style="font-size: 10px"
                >
                  <thead>
                    <tr>
                      <th>Mesa</th>
                      <th>Conta</th>
                      <th>Valor Previsto</th>
                      <th>Usuario Solicitou</th>
                      <th>Usuario Permitiu</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="r in dados.relatorioProblemas.taxaServico"
                      :key="`rel_txa_canc_${r.cod_conta}_${Math.random(
                        232323232
                      )}`"
                    >
                      <td>{{ r.mesa }}</td>
                      <td>{{ r.cod_conta }}</td>
                      <td>{{ r.valor_aprox | currency }}</td>
                      <td>
                        {{ r.usuario_solicitou }}
                        <br />
                        <small v-if="r.dt_solicitou">
                          {{ r.dt_solicitou | moment("DD/MM/YYYY H:mm:ss") }}
                        </small>
                      </td>
                      <td>
                        {{ r.usuario_autorizou }}
                        <br />
                        <small v-if="r.dt_resposta">
                          {{ r.dt_resposta | moment("DD/MM/YYYY H:mm:ss") }}
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="mt-4">
                <h5 class="text-danger"><b-icon-trash /> Pedidos Cancelados</h5>
                <table
                  class="table table-sm"
                  style="font-size: 10px"
                >
                  <thead>
                    <tr>
                      <th>Pedido</th>
                      <th>Tipo</th>
                      <th>Cliente</th>
                      <th>Valor</th>
                      <th>Usuario Solicitou</th>
                      <th>Usuario Permitiu</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="p in dados.relatorioProblemas.pedidosCancelados"
                      :key="`lin_ped_cance${p.cod_pedido}_${Math.random(
                        23232329
                      )}`"
                    >
                      <td>
                        {{ p.senha }} <br />
                        <small> #{{ p.cod_pedido }} </small>
                      </td>
                      <td>
                        {{ p.tipo_entrega }}
                      </td>
                      <td>
                        {{ p.cli }}<br />
                        <small>
                          {{ p.obs_canc }}
                        </small>
                      </td>
                      <td>
                        {{ p.vl_total | currency }}
                      </td>

                      <td>
                        {{ p.usuario_solicitou }}
                        <br />
                        <small v-if="p.dt_solicitou">
                          {{ p.dt_solicitou | moment("DD/MM/YYYY H:mm:ss") }}
                        </small>
                      </td>
                      <td>
                        {{ p.usuario_autorizou }}
                        <br />
                        <small v-if="p.dt_resposta">
                          {{ p.dt_resposta | moment("DD/MM/YYYY H:mm:ss") }}
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="mt-4">
                <h5 class="text-danger">
                  <b-icon-exclamation-triangle /> Pedidos com Problema
                </h5>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="mt-4">
                <h5 class="text-danger">
                  <i class="fas fa-coins"></i> Desconto em Pedidos - Concedidos
                </h5>
                <table
                  class="table table-sm"
                  style="font-size: 10px"
                >
                  <thead>
                    <tr>
                      <th>Pedido</th>
                      <th>Tipo</th>
                      <th>Cliente</th>
                      <th>Valor</th>
                      <th>Usuario Solicitou</th>
                      <th>Usuario Permitiu</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="p in dados.relatorioProblemas.descontos"
                      :key="`lin_ped_cance${p.cod_pedido}_${Math.random(
                        23232329
                      )}`"
                    >
                      <td>
                        {{ p.senha }} <br />
                        <small> #{{ p.cod_pedido }} </small>
                      </td>
                      <td>
                        {{ p.tipo_entrega }}
                      </td>
                      <td>
                        {{ p.cli }}<br />
                        <small>
                          {{ p.obs_canc }}
                        </small>
                      </td>
                      <td>
                        {{ p.vl_total | currency }}
                      </td>

                      <td>
                        {{ p.usuario_solicitou }}
                        <br />
                        <small v-if="p.dt_solicitou">
                          {{ p.dt_solicitou | moment("DD/MM/YYYY H:mm:ss") }}
                        </small>
                      </td>
                      <td>
                        {{ p.usuario_autorizou }}
                        <br />
                        <small v-if="p.dt_resposta">
                          {{ p.dt_resposta | moment("DD/MM/YYYY H:mm:ss") }}
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceiroLib from "../../libs/FinanceiroLib";
export default {
  components: {},
  props: {
    empresas: Array,
    data: Object,
  },
  mounted() {
    this.getResumoFinanceiro();
  },
  data() {
    return {
      dados: [],
      isMobile: this.$store.state.isMobile,
      showCaixas: false,
      showEntregas: false,
      showMesas: false,
      showRelatorio: false,
      resumoDepositos: [],
      tipoDetalheDeposito: 0,
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.getResumoFinanceiro();
      },
    },
    empresas: {
      deep: true,
      handler() {
        this.getResumoFinanceiro();
      },
    },
    tipoDetalheDeposito: function () {
      this.filterDetalhamentoDep();
    },
  },
  computed: {},
  methods: {
    async filterDetalhamentoDep() {
      this.dados.totais = {
        depositos: 0,
        pendencias: 0,
        total: 0,
        taxas: 0,
        pagamentos: 0,
      };
      if (this.dados.pendenciasCaixa && this.dados.pendenciasCaixa.length > 0) {
        this.dados.totais.pendencias += this.reducerSum(
          this.dados.pendenciasCaixa,
          "vl_passando"
        );
        this.dados.totais.pendencias -= this.reducerSum(
          this.dados.pendenciasCaixa,
          "vl_faltando"
        );
      }
      if (
        this.dados &&
        this.dados.depositos &&
        this.dados.depositos.length > 0
      ) {
        this.dados.totais.depositos = this.reducerSum(
          this.dados.depositos,
          "valor"
        );
        this.dados.resumoDepositos = this.reducerGroup(
          this.dados.depositos,
          this.tipoDetalheDeposito === 0 ? "historico" : "empresa"
        );

        for (let emp of this.dados.resumoDepositos) {
          this.dados.totais.taxas -= this.reducerSum(
            emp.lista.filter((x) => x.historico == "Taxas Bancárias"),
            "valor"
          );
          this.dados.totais.pagamentos -= this.reducerSum(
            emp.lista.filter((x) => x.historico == "Pagamento Funcionários"),
            "valor"
          );
          emp.historicos = this.reducerGroup(
            emp.lista,
            this.tipoDetalheDeposito === 1 ? "historico" : "empresa"
          );
          emp.valor = this.reducerSum(emp.lista, "valor");
          for (let hist of emp.historicos) {
            hist.carteiras = this.reducerGroup(hist.lista, "carteira");
            hist.valor = this.reducerSum(hist.lista, "valor");
            for (let cart of hist.carteiras) {
              cart.valor = this.reducerSum(cart.lista, "valor");
            }
          }
        }
      }
      this.dados.totais.total =
        this.dados.totais.depositos + this.dados.totais.pendencias;
      console.log("0--", this.dados);
    },
    async getResumoFinanceiro() {
      this.dados = await FinanceiroLib.getResumoFinanceiro({
        empresas: this.empresas,
        data: this.data,
      });
      console.log("dados recebidos", this.dados);

      this.filterDetalhamentoDep();
    },
  },
};
</script>

<style lang="scss" scoped></style>
